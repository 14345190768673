import React from "react";
import DownloadApp from "../components/DownloadApp";

function VoucherRefill() {
 
  const isEvzone = window.location.hostname === "evzone.wrenev.app";

  const logoSrc = isEvzone ? "/evzone_logo.png" : "/wrenev_logo.svg";
  const logoAlt = isEvzone ? "EVzone Logo" : "WrenEV Logo";
  

  return (
    <div className="centered-div">
        <img
          src={logoSrc}
          alt={logoAlt}
          className="logo"
          height="80 px"
        />
      <DownloadApp />
      <p>Please open the app and then scan the voucher QR code.</p>
    </div>
  );
}

export default VoucherRefill;
