import './styles/styles.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AppRoute from './pages/AppRoute';
import ChargerPage from './pages/ChargerPage';
import VoucherRefill from './pages/VoucherRefill';
import Download from './pages/Download';
import React, { useEffect } from 'react';

function App() {
  useEffect(() => {
    const isEvzone = window.location.hostname === "evzone.wrenev.app";
    const logoSrc = isEvzone ? "/evzone_launcher_icon.png" : "/favicon.ico";
    const favicon = document.querySelector("link[rel='icon']");
    if (favicon) {
      favicon.href = logoSrc;
    }

    const root = document.documentElement;
    if (isEvzone) {
      root.style.setProperty('--primary-color', 'rgb(13, 27, 38)');
      root.style.setProperty('--secondary-color', 'rgb(133, 207, 220)');
    } else {
      root.style.setProperty('--primary-color', 'rgb(1, 37, 44)');
      root.style.setProperty('--secondary-color', 'rgb(136, 223, 149)');
    }
    
  }, []);


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppRoute />} />
        <Route path="/charger" element={<ChargerPage />} />
        <Route path="/refill_voucher" element={<VoucherRefill />} />
        <Route path="/download" element={<Download />} />
        
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
