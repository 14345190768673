import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';
import "../styles/styles.css";

export const Loader = () => (
  <div className="loader-container">
    <PulseLoader color="rgb(1, 37, 44)" />
  </div>
);

export const Error = ({ error }) => (
  <div className="error-container">
    <p>Error: {error}</p>
  </div>
);

export const DetailsContainer = ({ data }) => (
  <div className="details-container">
    {data && (
      <div className="details">
        <div className="detail">
          <p className="label">Charge Point:</p>
          <p className="value">{data.display_name}</p>
        </div>
        <div className="detail">
          <p className="label">Connector:</p>
          <p className="value">{data.connectors[0].name}</p>
        </div>
        <div className="detail">
          <p className="label">Location:</p>
          <p className="value">{data.site_address}</p>
        </div>
        <div className="detail">
          <p className="label">Status:</p>
          <p className="value">{data.connectors[0].status}</p>
        </div>
        <div className="detail">
          <p className="label">Price:</p>
          <p className="value">{data.connectors[0].pricing} {data.connectors[0].currency}/{data.connectors[0].pricing_unit}</p>
        </div>
      </div>
    )}
    <div className="button-container">
      <p> Use our mobile app to start charging. </p>
    </div>
  </div>
);

