import React from "react";
// import { isMobile, isAndroid, isIOS } from 'react-device-detect';

// import {GOOGLE_PLAY_LINK, APP_STORE_LINK} from "../constants"

import DownloadApp from "../components/DownloadApp";

function AppRoute() {
  // if (isMobile && isAndroid) {
  //   window.location.href = GOOGLE_PLAY_LINK;
  // } else if(isMobile && isIOS) {
  //   window.location.href = APP_STORE_LINK;
  // }

  const isEvzone = window.location.hostname === "evzone.wrenev.app";

  const logoSrc = isEvzone ? "/evzone_logo.png" : "/wrenev_logo.svg";
  const logoAlt = isEvzone ? "EVzone Logo" : "WrenEV Logo";
  

  return (
    <div className="centered-div">
        <img
          src={logoSrc}
          alt={logoAlt}
          className="logo"
          height="80 px"
        />
      <DownloadApp />
    </div>
  );
}

export default AppRoute;
