import {API} from "../constants";

export const fetchData = (cpk, cid) => {
    return fetch(API, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "charge_point_key": cpk,
            "connector_id":  parseInt(cid, 10)
        }),
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Invalid charge point');
        }
        return response.json();
    })
    .catch(error => {
        throw error;
    });
};
