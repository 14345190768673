import React, { useEffect } from "react";
import DownloadApp from "../components/DownloadApp";
import {WRENEV_GOOGLE_PLAY_LINK, WRENEV_APP_STORE_LINK, EVZONE_GOOGLE_PLAY_LINK, EVZONE_APP_STORE_LINK} from "../constants"
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

function Download() {
  const isEvzone = window.location.hostname === "evzone.wrenev.app";

  const logoSrc = isEvzone ? "/evzone_logo.png" : "/wrenev_logo.svg";
  const logoAlt = isEvzone ? "EVzone Logo" : "WrenEV Logo";

  const downloadAppStoreLink = isEvzone ? EVZONE_APP_STORE_LINK : WRENEV_APP_STORE_LINK;
  const downloadPlayStoreLink = isEvzone ? EVZONE_GOOGLE_PLAY_LINK : WRENEV_GOOGLE_PLAY_LINK;
  
  useEffect(() => {
    if (isMobile) {
      if (isAndroid) {
        window.location.href = downloadPlayStoreLink;
      } else if (isIOS) {
        window.location.href = downloadAppStoreLink;
      }
    }
  }, [downloadAppStoreLink, downloadPlayStoreLink]);

  return (
    <div className="centered-div">
        <img
          src={logoSrc}
          alt={logoAlt}
          className="logo"
          height="80 px"
        />
      <DownloadApp />
    </div>
  );
}

export default Download;
