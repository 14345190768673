import React from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import {WRENEV_GOOGLE_PLAY_LINK, WRENEV_APP_STORE_LINK, EVZONE_GOOGLE_PLAY_LINK, EVZONE_APP_STORE_LINK} from "../constants"

import "../styles/styles.css";

function DownloadApp() {
  const width = window.innerWidth * 0.4;

  const isEvzone = window.location.hostname === "evzone.wrenev.app";
  const downloadAppStoreLink = isEvzone ? EVZONE_APP_STORE_LINK : WRENEV_APP_STORE_LINK;
  const downloadPlayStoreLink = isEvzone ? EVZONE_GOOGLE_PLAY_LINK : WRENEV_GOOGLE_PLAY_LINK;

  if (isMobile && isAndroid) {
    return (
      <a href={downloadPlayStoreLink} target="_blank" rel="noopener noreferrer">
        <img
          src= "/googleplay.svg"
          alt="Google Play"
          style={{ width, height: 50, objectFit: 'contain' }}
        />
      </a>
    );
  } else if (isMobile && isIOS) {
    return (
      <a href={downloadAppStoreLink} target="_blank" rel="noopener noreferrer">
        <img
          src="/appstore.svg"
          alt="App Store"
          style={{ width, height: 50, objectFit: 'contain' }}
        />
      </a>
    );
  } else {
    return null;
  }
}

export default DownloadApp;
