import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { fetchData } from "../services/api";
import DownloadApp from "../components/DownloadApp";
import { Loader, Error, DetailsContainer } from "../components/ChargeDetails";
import "../styles/styles.css";

function ChargerPage() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const cpk = query.get("cpk");
  const cid = query.get("cid");

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData(cpk, cid)
      .then((responseData) => {
        setData(responseData);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [cpk, cid]);

  let details;
  if (loading) {
    details = <Loader />;
  } else if (error) {
    details = <Error error={error} />;
  } else {
    details = <DetailsContainer data={data} />;
  }

  const isEvzone = window.location.hostname === "evzone.wrenev.app";

  const logoSrc = isEvzone ? "/evzone_logo.png" : "/wrenev_logo.svg";
  const logoAlt = isEvzone ? "EVzone Logo" : "WrenEV Logo";
  

  return (
    <div className="charge-point-page">
      <div className="logo-container">
        {data && (
          <img
            src={logoSrc}
            alt={logoAlt}
            className="logo"
            height="80 px"
          />
        )}
      </div>
      <div className="content-container">
        {details}
      </div>

      <DownloadApp />
      
      <br/>
      {isEvzone && (
        <img
          src="/powered_by_logo.svg"
          alt="EVzone Logo"
          className="logo"
          height="50px"
        />
      )}
    </div>
  );
}

export default ChargerPage;
